<template>
  <Loader v-if="customer === null" :inContent="true" :noText="true"
    >Loading...</Loader
  >
  <div
    v-else
    style="display: flex; align-items: center; justify-content: space-evenly; margin: 0px; flex-wrap: wrap;"
  >
    <KTPortlet
      data-app="ads"
      v-for="(ad, index) in customer.ads"
      v-bind:key="index"
      :title="ad.name"
      style="margin: 5px; max-width: 800px;"
    >
      <template v-slot:toolbar>
        <v-chip
          v-if="!ad.active"
          style="width: 90px; text-align: center;"
          color="bg-danger text-white"
          class="ma-2"
          x-small
          >AD Disabled</v-chip
        >
      </template>
      <template slot="body">
        <div
          class="card ad"
          style="text-align: left; padding: 0; background: none;"
        >
          <span style="font-size: 10px;">{{
            ad.url.replace("http://", "").replace("https://", "")
          }}</span>
          <h3 style="margin: 0.5rem; margin-left: 0;">
            {{ ad.headline1 }}
            <span v-if="ad.headline2 !== ''">| {{ ad.headline2 }}</span>
          </h3>
          <div>
            {{ ad.description1 }}
          </div>
          <div>
            {{ ad.description2 }}
          </div>
          <!-- <div style="max-width : 500px; font-size: 8px">
            <i>
            {{ ad.keywords.join(', ') }}
            </i>
          </div> -->
          <div style="max-width : 500px; font-size: 10px;margin-top:5px;">
            <span
              v-for="(siteL, index) in customer.site_links"
              v-bind:key="siteL.url"
              style="padding-right: 5px;"
            >
              <span v-if="index !== 0" style="padding-right: 5px;">
                ·
              </span>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    style="cursor: default;"
                    class="text-primary"
                    >{{ siteL.title }}</span
                  >
                </template>
                <span>Link points to: {{ siteL.url }}</span>
              </v-tooltip>
            </span>
          </div>
        </div>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
import TLib from "./lib";
//import moment from "moment";
//import ApiService from "@/common/api.service";

export default {
  components: {
    KTPortlet,
    Loader
  },
  watch: {},
  data() {
    return {
      customer: null
    };
  },
  methods: {
    updateService(serviceId) {
      let self = this;
      self.$data.customer = null;
      TLib.getAdBudClient(self, serviceId)
        .then(z => {
          self.$data.customer = z;
        })
        .catch(self.$log.error);
    }
    /*updateUsage(serviceId) {
      let self = this;
      self.$data.usage = null;
      TLib.getUsage(this, serviceId)
        .then(x => {
          self.$nextTick(() => {
            self.$data.usage = x;
          });
        })
        .catch(x => {
          self.$log.error(x);
          self.$log.error("Error loading content");
        });
    }*/
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
    //this.$eventBus.off(`cache-crm:services`);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Advertising" },
      { title: "Adverts" }
    ]);

    this.updateService(this.$route.params.serviceId);

    let self = this;

    self.$eventBus.on(`switch-service-selected`, x => self.updateService(x));
    //self.$eventBus.on(`cache-crm:services`, () => self.resync(false));
    //self.resync();
  }
};
</script>
